
import vClickOutside from 'click-outside-vue3';
import { storage, native } from '@/tools';
import inappTools from '@/common/inappTools';
import vImageLazyLoading from '../../common/directives/ImgLazyLoading';

import promotionMixins from '../../common/mixins/promotion';
import AppNav from '../../components/AppNav.vue';

export default {
  name: 'MarketPrice',
  components: {
    AppNav,
  },
  directives: {
    clickOutside: vClickOutside.directive,
    'img-lazy-loading': vImageLazyLoading,
  },
  mixins: [promotionMixins],
  data() {
    return {
      imgUrls: {
        main_img: '//res.tutoring.co.kr/res/images/img/marketprice/main_img.png',
        main_img_sm: '//res.tutoring.co.kr/res/images/img/marketprice/main_img_sm.png',
        marketprice_number01: '//res.tutoring.co.kr/res/images/img/marketprice/marketprice_number01.png',
        marketprice_list: '//res.tutoring.co.kr/res/images/img/marketprice/marketprice_list.png',
        tab_topdate: '//res.tutoring.co.kr/res/images/img/marketprice/tab_topdate.png',
        marketprice_global_graph01: '//res.tutoring.co.kr/res/images/img/marketprice/marketprice_global_graph01.png',
        marketprice_global_graph02: '//res.tutoring.co.kr/res/images/img/marketprice/marketprice_global_graph02.png',
        marketprice_global_table01: '//res.tutoring.co.kr/res/images/img/marketprice/marketprice_global_table01.png',
        marketprice_global_table02: '//res.tutoring.co.kr/res/images/img/marketprice/marketprice_global_table02.png',
        marketprice_global_table03: '//res.tutoring.co.kr/res/images/img/marketprice/marketprice_global_table03.png',

        marketprice_native_graph01: '//res.tutoring.co.kr/res/images/img/marketprice/marketprice_native_graph01.png',
        marketprice_native_graph02: '//res.tutoring.co.kr/res/images/img/marketprice/marketprice_native_graph02.png',
        marketprice_native_table01: '//res.tutoring.co.kr/res/images/img/marketprice/marketprice_native_table01.png',
        marketprice_native_table02: '//res.tutoring.co.kr/res/images/img/marketprice/marketprice_native_table02.png',
        reward_img05: '//res.tutoring.co.kr/res/images/img/marketprice/reward_img05.png',
        marketprice_system: '//res.tutoring.co.kr/res/images/img/marketprice/marketprice_system.png',
        marketprice_system02: '//res.tutoring.co.kr/res/images/img/marketprice/marketprice_system02.png',
        marketprice_number02: '//res.tutoring.co.kr/res/images/img/marketprice/marketprice_number02.png',
        emoji_01: '//res.tutoring.co.kr/res/images/img/marketprice/emoji_01.png',
        emoji_02: '//res.tutoring.co.kr/res/images/img/marketprice/emoji_02.png',
        emoji_03: '//res.tutoring.co.kr/res/images/img/marketprice/emoji_03.png',
        emoji_04: '//res.tutoring.co.kr/res/images/img/marketprice/emoji_04.png',
        marketprice_reward: '//res.tutoring.co.kr/res/images/img/marketprice/marketprice_reward.png',
      },
      tab: 'global',
      bottomCtaUp: false,
      isMobileBottomSheetOpen: false,
    };
  },
  created() {},
  mounted() {
    this.onBottomSheetOn();
  },
  beforeUnmount() {
    window.removeEventListener('scroll', this.onBottomSheetOn);
  },

  methods: {
    switchTab(tab) {
      this.tab = tab;
    },
    purchasePlan() {
      if (inappTools.isInApp()) {
        // 인앱일 때
        storage.session.set('routeAfterWebViewClosed', '/app/home/ticket/select/option');
        this.trackingUserAction(`readytogo_footer`);
        native.close();
      } else {
        // 웹일 때
        const url = `https://${document.location.host}/v2/plan/all/pass`; // 수강신청 메뉴
        window.location.href = url;
      }
    },
    onBottomSheetOn() {
      window.addEventListener('scroll', () => {
        if (window.pageYOffset > 1000) {
          this.bottomCtaUp = true;
        } else {
          this.bottomCtaUp = false;
        }
      });
    },
    buyCourse() {
      if (inappTools.isInApp()) {
        // 인앱일 때
        storage.session.set('routeAfterWebViewClosed', '/app/home/ticket/allPass');
        native.close();
      } else {
        // 웹일 때
        const url = `https://${document.location.host}/v2/plan/all`;
        window.location.href = url;
      }
    },
    goToReportRewardSystem() {
      const url = `https://${document.location.host}/v2/promotions/reportReward`;
      window.location.href = url;
    },
  },
  computed: {
    appView() {
      return inappTools.isInApp();
    },
    appPadding() {
      if (inappTools.isInApp()) {
        return '48px';
      }
      return '0px';
    },
    exceptBnrScroll() {
      return !sessionStorage.getItem('lineBnrClose') ? 105 : 0;
    },
  },
  watch: {},
};
