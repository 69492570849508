import { renderSlot as _renderSlot, mergeProps as _mergeProps, createElementVNode as _createElementVNode, vShow as _vShow, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7b0414ea"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "popup-toast" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _mergeProps({
      class: ["toast", {
        'toast--visible': $props.isPopup,
      }]
    }, _ctx.$attrs), [
      _renderSlot(_ctx.$slots, "default", {}, undefined, true)
    ], 16 /* FULL_PROPS */)
  ], 512 /* NEED_PATCH */)), [
    [_vShow, $props.isPopup]
  ])
}