<template>
  <!-- app nav -->
  <AppNav v-if="appView">링고라 상설특가제</AppNav>

  <div class="promotion" :style="{ 'padding-top': appPadding }">
    <section class="main_section">
      <span class="showOnlyMobile">
        <img v-img-lazy-loading :data-url="imgUrls.main_img_sm" class="main_img_sm" alt="main_img" />
      </span>
      <span class="showOnlyWeb">
        <img v-img-lazy-loading :data-url="imgUrls.main_img" class="main_img" alt="main_img" />
      </span>
    </section>
    <section class="desc_section">
      <p class="title">상설특가제란?</p>
      <p class="desc">
        1:1 원어민 회화를 더 많은 분들이 누릴 수 있도록
        <span class="showOnlyMobile"><br /></span>
        업계최저가 기준에 맞추어
        <span class="showOnlyWeb"><br /></span>
        <span class="purple"><b>상시 판매 가격</b></span>
        <span>을</span>
        {{ ' ' }}
        <span class="showOnlyMobile"><br /></span>
        <span class="purple"><b>정가대비 최대 50%이상 할인</b></span>
        <span>하는</span>
        <span class="showOnlyMobile"><br /></span>
        링고라의 가격 제도
      </p>
    </section>
    <section class="low_price_section">
      <img v-img-lazy-loading :data-url="imgUrls.marketprice_number01" class="img_number" alt="image" />
      <p class="title">
        1:1 원어민 회화
        <br />
        업계 최저가 보장
      </p>
      <p class="desc">
        링고라는 교육 전문 튜터와의 1:1 원어민 회화를
        <br />
        가장 저렴하게 제공합니다.
      </p>
      <p class="desc_text">
        <span class="bold">비교 기준</span>
        <br />
        링고라와 동일한 조건의 1:1 원어민 회화 수업
      </p>
      <img v-img-lazy-loading :data-url="imgUrls.marketprice_list" class="img_desc" alt="image" />
      <p class="button_title" style="margin-bottom: 12px">대표 수강권 가격 비교</p>
      <div>
        <img v-img-lazy-loading :data-url="imgUrls.tab_topdate" class="img_date" alt="image" />
      </div>
      <div class="compare_tab">
        <button class="tab_button no-select" :class="{ clicked: tab === 'global' }" @click="switchTab('global')">
          글로벌 튜터
        </button>
        <!-- <button class="tab_button no-select" :class="{ clicked: tab === 'native' }" @click="switchTab('native')">
          네이티브 튜터
        </button> -->
      </div>
    </section>
    <section class="compare_tutor_section">
      <div v-if="tab === 'global'">
        <p class="title">
          1회 20분
          <br />
          수업당 가격 비교
        </p>
        <img v-img-lazy-loading :data-url="imgUrls.marketprice_global_graph01" class="graph" alt="image" />
        <p class="desc">12개월 주 2회(96회) 20분 수업 수강권 기준</p>
        <p class="title">
          정가 대비
          <br />
          할인율 및 상시 판매가
        </p>
        <img v-img-lazy-loading :data-url="imgUrls.marketprice_global_graph02" class="graph" alt="image" />
        <p class="desc">12개월 주 2회(96회) 20분 수업 수강권 기준</p>
        <p class="title">
          업체별 1회 수업
          <br />
          가격 비교표
        </p>
        <img v-img-lazy-loading :data-url="imgUrls.marketprice_global_table01" class="table" alt="image" />
        <img v-img-lazy-loading :data-url="imgUrls.marketprice_global_table02" class="table" alt="image" />
        <img v-img-lazy-loading :data-url="imgUrls.marketprice_global_table03" class="table" alt="image" />
      </div>
      <div v-if="tab === 'native'">
        <p class="title">
          1회 20분
          <br />
          수업당 가격 비교
        </p>
        <img v-img-lazy-loading :data-url="imgUrls.marketprice_native_graph01" class="graph" alt="image" />
        <p class="desc">6개월 주 3회(72회) 20분 수업 수강권 기준</p>
        <p class="title">
          정가 대비
          <br />
          할인율 및 상시 판매가
        </p>
        <img v-img-lazy-loading :data-url="imgUrls.marketprice_native_graph02" class="graph" alt="image" />
        <p class="desc">6개월 주 3회(72회) 20분 수업 수강권 기준</p>
        <p class="title">
          업체별 1회 수업
          <br />
          가격 비교표
        </p>
        <img v-img-lazy-loading :data-url="imgUrls.marketprice_native_table01" class="table" alt="image" />
        <img v-img-lazy-loading :data-url="imgUrls.marketprice_native_table02" class="table" alt="image" />
      </div>
    </section>
    <section class="compare_notice_section">
      <div class="container">
        <p class="title">가격비교 기준</p>
        <ul>
          <li class="list__item">수강권 가격은 표기된 날짜 기준으로 비교하였으며 수시로 업데이트됩니다.</li>
          <li class="list__item">추가 할인 쿠폰 및 프로모션이 적용되지 않은 상시 판매가로 비교하였습니다.</li>
          <li class="list__item">
            동일 기간, 동일 튜터 타입 수강권의 판매가로 비교하되, 만약 수업 시간이나 기간 내 수업 횟수 등 구성이 다른
            수강권만 존재하는 경우에는 총 수업 분수가 가장 유사한 수강권으로 비교하며, 분당 가격 기준 링고라 판매
            수강권과 동일한 조건으로 금액 환산하여 가격을 비교하였습니다.
          </li>
          <li class="list__item">
            링고라의 경우 모든 시간대에 실시간 수업이 가능하기 때문에 특정 시간대나 요일 수업 조건부 할인이 적용되지
            않은 상시 이용 조건의 수강권으로만 비교하였습니다.
          </li>
          <li class="list__item">
            상시 수업 가능한 수강권이 없는 경우, 새벽 시간대 제외 (KST 0시 ~ 6시) 모든 시간대 수업의 가격이 동일한
            수강권으로만 비교하였습니다.
          </li>

          <li class="list__item">글로벌(Global) 튜터: 영어 사용국 출신 튜터 수업을 의미합니다.</li>
          <li class="list__item">가격 비교 및 최저가 정책은 글로벌 튜터 음성회화 수강권이 한하여 적용됩니다</li>
        </ul>
      </div>
    </section>
    <section class="question_section">
      <div class="title">
        <p class="q">Q.</p>
        왜 수강권을
        <br />
        저렴하게 판매하나요?
      </div>
      <div class="card">
        <div class="bold_text">
          “경제력이 교육을
          <br />
          지배하지 않는 세상을 만든다.”
        </div>
        <div class="normal_text">
          보다 많은 분들이 최고 수준의 1:1 회화 서비스를
          <br />
          경험할 수 있도록 기술 혁신을 통해 합리적인 가격으로
          <br />
          서비스를 제공하고 있습니다.
        </div>
        <img v-img-lazy-loading :data-url="imgUrls.reward_img05" class="reward_img" alt="image" />
      </div>
      <div class="title">
        <p class="q">Q.</p>
        가격이 저렴하면
        <br />
        퀄리티가 안 좋은거 아닌가요?
        <br />
      </div>
      <div class="card">
        <div class="normal_text" style="margin-bottom: 0">
          그렇지 않습니다! 기술 혁신을 통해
          <br />
          <b>불필요한 비용을 절감</b>
          했기 때문에 1:1 수업을
          <br />
          합리적인 가격에 판매할 수 있습니다.
        </div>
      </div>
      <div class="title">
        <p class="q">Q.</p>
        기술 혁신이
        <br />
        어떻게 비용을 절감하나요?
      </div>
      <div class="card">
        <div class="normal_text">
          콜센터 두고 튜터를 상주시키는 기존의 전화영어
          <br />
          방식은 불필요한 관리 비용을 발생시킵니다.
        </div>
        <div class="normal_text">
          링고라는
          <b>효율적인 비용으로 서비스를 운영</b>
          할 수 있도록
          <br />
          기술혁신을 통해 콜센터 없이 운영 가능한 통신 기술과
          <br />
          학습 시스템을 마련하였습니다.
        </div>
        <img v-img-lazy-loading :data-url="imgUrls.marketprice_system" class="img_system" alt="image" />
        <div class="small_text">
          * RTC (Real Time Communication)
          <br />
          **규모의 경제 (Economy of scale):
          <br />
          생산량이 늘어남에 따라 평균 비용이 줄어드는 현상
        </div>
        <div class="button_title">운영 방식 비교</div>
        <img v-img-lazy-loading :data-url="imgUrls.marketprice_system02" class="img_system02" alt="image" />
      </div>
      <button class="button_start" @click="buyCourse()">최저가 영어회화 시작</button>
    </section>
    <section class="report_reward_section">
      <img v-img-lazy-loading :data-url="imgUrls.marketprice_number02" class="img_number" alt="image" />
      <p class="title">
        최저가
        <br />
        신고 보상제 실시
      </p>
      <p class="desc" style="margin-bottom: 40px">
        링고라보다 저렴한 1:1 원어민 회화
        <br />
        수강권을 알려주세요!
      </p>
      <div class="card_container">
        <div class="card">
          <div class="step">STEP. 1</div>
          <img v-img-lazy-loading :data-url="imgUrls.emoji_01" class="icon" alt="icon" />
          <div class="text">
            최저가 보상 기준
            <br />
            및 참여자 조건 확인
          </div>
        </div>
        <div class="card">
          <div class="step">STEP. 2</div>
          <img v-img-lazy-loading :data-url="imgUrls.emoji_02" class="icon" alt="icon" />
          <div class="text">
            양식을 작성하여
            <br />
            이메일로 신청 접수
          </div>
        </div>
        <div class="card">
          <div class="step">STEP. 3</div>
          <img v-img-lazy-loading :data-url="imgUrls.emoji_03" class="icon" alt="icon" />
          <div class="text">
            보상 기준에 따라
            <br />
            심사 진행
          </div>
        </div>
        <div class="card">
          <div class="step">STEP. 4</div>
          <img v-img-lazy-loading :data-url="imgUrls.emoji_04" class="icon" alt="icon" />
          <div class="text">
            심사 결과에 따라
            <br />
            <b>리워드 지급</b>
          </div>
        </div>
      </div>
      <p class="desc">
        제보한 타사 수강권이 보상 기준에 부합하는 경우,
        <br />
        신고자에게는
        <span class="purple">차액별 상품권</span>
        으로 보상해 드리고
        <span class="showOnlyMobile"><br /></span>
        링고라는 업계최저가 기준에 맞추어 가격을 조정합니다.
      </p>
      <p class="button_title">최저가 신고 혜택</p>
      <p class="desc">
        링고라보다 저렴한 수강권을 찾아주신 분께 차액만큼
        <br />
        백화점 상품권으로 보상해 드립니다!
      </p>
      <img v-img-lazy-loading :data-url="imgUrls.marketprice_reward" class="img_gift_card" alt="icon" />
      <p class="desc desc_small">
        *보상 혜택은 차액 구간별 차등 지급되며
        <br />
        ‘최저가 신고보상제' 안내 페이지에서 상세 내용을 확인하시기 바랍니다.
      </p>
      <button class="button_detail" @click="goToReportRewardSystem()">자세히 보기</button>
    </section>

    <section class="challenge-cta is-info" :class="{ 'ani-bottom-up': bottomSheetUp }" id="challengeCta">
      <!-- l-select 에 is-on 넣을때 얘도 같이 is-on 넣어주세요  -->
      <div class="dim"></div>
      <button v-if="appView" type="button" class="challenge-cta__wrap" @click="buyCourse()">
        최저가 수강권 확인
        <div class="icon_cta" />
      </button>
    </section>
  </div>
</template>

<script lang="ts">
import vClickOutside from 'click-outside-vue3';
import { storage, native } from '@/tools';
import inappTools from '@/common/inappTools';
import vImageLazyLoading from '../../common/directives/ImgLazyLoading';

import promotionMixins from '../../common/mixins/promotion';
import AppNav from '../../components/AppNav.vue';

export default {
  name: 'MarketPrice',
  components: {
    AppNav,
  },
  directives: {
    clickOutside: vClickOutside.directive,
    'img-lazy-loading': vImageLazyLoading,
  },
  mixins: [promotionMixins],
  data() {
    return {
      imgUrls: {
        main_img: '//res.tutoring.co.kr/res/images/img/marketprice/main_img.png',
        main_img_sm: '//res.tutoring.co.kr/res/images/img/marketprice/main_img_sm.png',
        marketprice_number01: '//res.tutoring.co.kr/res/images/img/marketprice/marketprice_number01.png',
        marketprice_list: '//res.tutoring.co.kr/res/images/img/marketprice/marketprice_list.png',
        tab_topdate: '//res.tutoring.co.kr/res/images/img/marketprice/tab_topdate.png',
        marketprice_global_graph01: '//res.tutoring.co.kr/res/images/img/marketprice/marketprice_global_graph01.png',
        marketprice_global_graph02: '//res.tutoring.co.kr/res/images/img/marketprice/marketprice_global_graph02.png',
        marketprice_global_table01: '//res.tutoring.co.kr/res/images/img/marketprice/marketprice_global_table01.png',
        marketprice_global_table02: '//res.tutoring.co.kr/res/images/img/marketprice/marketprice_global_table02.png',
        marketprice_global_table03: '//res.tutoring.co.kr/res/images/img/marketprice/marketprice_global_table03.png',

        marketprice_native_graph01: '//res.tutoring.co.kr/res/images/img/marketprice/marketprice_native_graph01.png',
        marketprice_native_graph02: '//res.tutoring.co.kr/res/images/img/marketprice/marketprice_native_graph02.png',
        marketprice_native_table01: '//res.tutoring.co.kr/res/images/img/marketprice/marketprice_native_table01.png',
        marketprice_native_table02: '//res.tutoring.co.kr/res/images/img/marketprice/marketprice_native_table02.png',
        reward_img05: '//res.tutoring.co.kr/res/images/img/marketprice/reward_img05.png',
        marketprice_system: '//res.tutoring.co.kr/res/images/img/marketprice/marketprice_system.png',
        marketprice_system02: '//res.tutoring.co.kr/res/images/img/marketprice/marketprice_system02.png',
        marketprice_number02: '//res.tutoring.co.kr/res/images/img/marketprice/marketprice_number02.png',
        emoji_01: '//res.tutoring.co.kr/res/images/img/marketprice/emoji_01.png',
        emoji_02: '//res.tutoring.co.kr/res/images/img/marketprice/emoji_02.png',
        emoji_03: '//res.tutoring.co.kr/res/images/img/marketprice/emoji_03.png',
        emoji_04: '//res.tutoring.co.kr/res/images/img/marketprice/emoji_04.png',
        marketprice_reward: '//res.tutoring.co.kr/res/images/img/marketprice/marketprice_reward.png',
      },
      tab: 'global',
      bottomCtaUp: false,
      isMobileBottomSheetOpen: false,
    };
  },
  created() {},
  mounted() {
    this.onBottomSheetOn();
  },
  beforeUnmount() {
    window.removeEventListener('scroll', this.onBottomSheetOn);
  },

  methods: {
    switchTab(tab) {
      this.tab = tab;
    },
    purchasePlan() {
      if (inappTools.isInApp()) {
        // 인앱일 때
        storage.session.set('routeAfterWebViewClosed', '/app/home/ticket/select/option');
        this.trackingUserAction(`readytogo_footer`);
        native.close();
      } else {
        // 웹일 때
        const url = `https://${document.location.host}/v2/plan/all/pass`; // 수강신청 메뉴
        window.location.href = url;
      }
    },
    onBottomSheetOn() {
      window.addEventListener('scroll', () => {
        if (window.pageYOffset > 1000) {
          this.bottomCtaUp = true;
        } else {
          this.bottomCtaUp = false;
        }
      });
    },
    buyCourse() {
      if (inappTools.isInApp()) {
        // 인앱일 때
        storage.session.set('routeAfterWebViewClosed', '/app/home/ticket/allPass');
        native.close();
      } else {
        // 웹일 때
        const url = `https://${document.location.host}/v2/plan/all`;
        window.location.href = url;
      }
    },
    goToReportRewardSystem() {
      const url = `https://${document.location.host}/v2/promotions/reportReward`;
      window.location.href = url;
    },
  },
  computed: {
    appView() {
      return inappTools.isInApp();
    },
    appPadding() {
      if (inappTools.isInApp()) {
        return '48px';
      }
      return '0px';
    },
    exceptBnrScroll() {
      return !sessionStorage.getItem('lineBnrClose') ? 105 : 0;
    },
  },
  watch: {},
};
</script>

<style lang="scss" scoped src="../../assets/newPromotions.scss"></style>
<style lang="scss" scoped>
.showOnlyMobile {
  display: none;
}
.showOnlyWeb {
  display: inline;
}
@media (max-width: 991px) {
  .showOnlyMobile {
    display: inline;
  }
  .showOnlyWeb {
    display: none;
  }
}
.button_title {
  display: inline-block;
  background: #000000;
  border-radius: 30px;
  padding: 8px 26px;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  color: #ffffff;
  margin: 60px auto 30px auto;
}
.main_section {
  background: #000;
  .main_img {
    display: block;
    margin: 0 auto;
    width: 550px;
    /* height: 520px; */
  }
  .main_img_sm {
    display: block;
    margin: 0 auto;
    width: 360px;
    height: 520px;
  }
}
.desc_section {
  background: #f1f0f5;
  padding: 40px 0;
  text-align: center;

  .title {
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    color: #000000;
    margin-bottom: 16px;
  }
  .desc {
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: #666666;
    .purple {
      color: #5d04d9;
    }
  }
}
.low_price_section {
  padding: 80px 0 0 0;
  text-align: center;
  img.img_number {
    width: 28px;
    height: 31px;
    margin-bottom: 10px;
  }
  .title {
    font-weight: 700;
    font-size: 26px;
    line-height: 31px;
    color: #000000;
    margin-bottom: 10px;
  }
  .desc {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #666666;
    margin-bottom: 40px;
  }
  img.img_desc {
    width: 296px;
    height: 218px;
    display: block;
    margin: 0 auto 10px auto;
  }
  img.img_date {
    width: 312px;
    margin-bottom: 15px;
  }
  .desc_text {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #999999;
    margin-bottom: 16px;
    .bold {
      font-weight: 700;
    }
  }
  .desc_text_compare {
    font-weight: 400;
    font-size: 10px;
    line-height: 13px;
    color: #85808d;
    margin-bottom: 30px;
  }
  .compare_tab {
    border-bottom: 1px solid #e8e6ed;
    .tab_button {
      font-weight: 400;
      font-size: 16px;
      line-height: 19px;
      color: #000000;
      /* &:last-child {
        margin-left: 40px;
      } */
      &.clicked {
        font-weight: 700;
        color: #5d04d9;
        border-bottom: solid 2px #5d04d9;
        padding-bottom: 8px;
      }
    }
  }
}
.compare_tutor_section {
  background: #f1f0f5;
  padding: 40px 0 60px 0;
  text-align: center;
  .title {
    font-weight: 700;
    font-size: 18px;
    line-height: 21px;
    color: #000000;
    margin-bottom: 20px;
  }
  .desc {
    font-weight: 400;
    font-size: 12px;
    line-height: 17px;
    color: #666666;
    margin-bottom: 40px;
  }
  img.graph {
    width: 312px;
    margin-bottom: 10px;
  }
  img.table {
    width: 360px;
    display: block;
    margin: 0 auto 30px auto;
  }
}
.compare_notice_section {
  background: #e8e6ed;
  padding: 40px 24px 80px 24px;
  color: #666666;
  .container {
    max-width: 960px;
    margin: 0 auto;
  }
  .title {
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    color: #666666;
    margin-bottom: 11px;
  }
  .list__item {
    position: relative;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    text-indent: 0;
    &:before {
      content: '\B7';
      position: absolute;
      top: 0;
      left: -7px;
    }
  }
}
.question_section {
  background: #000000;
  padding: 80px 24px;
  text-align: center;
  .title {
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    color: #ffffff;
    margin-bottom: 30px;
    .q {
      margin-bottom: 4px;
    }
  }
  .card {
    max-width: 300px;
    background: rgba(55, 55, 55, 0.8);
    border-radius: 20px;
    padding: 40px 15px;
    margin: 0 auto 60px auto;
    .bold_text {
      font-weight: 700;
      font-size: 18px;
      line-height: 21px;
      color: #ffffff;
      margin-bottom: 12px;
    }
    .normal_text {
      font-weight: 400;
      font-size: 13px;
      line-height: 18px;
      color: #d6d3dd;
      margin-bottom: 10px;
    }
    img.reward_img {
      width: 272px;
      height: 210px;
      margin-top: 4px;
    }
    .small_text {
      font-weight: 400;
      font-size: 10px;
      line-height: 13px;
      color: #ffffff;
      opacity: 0.5;
    }
    .button_title {
      padding: 8px 26px;
      background: #ffffff;
      border-radius: 30px;
      font-weight: 700;
      font-size: 16px;
      color: #333333;
      margin-bottom: 30px;
    }
    img.img_system {
      width: 239px;
      margin: 30px auto;
    }
    img.img_system02 {
      width: 302px;
      @media (max-width: 991px) {
        width: 282px;
      }
    }
  }
  .button_start {
    width: 310px;
    padding: 20px;
    background: #e8e6ed;
    font-weight: 700;
    font-size: 18px;
    color: #000000;
  }
}
.report_reward_section {
  padding: 80px 0 100px 0;
  background: #f1f0f5;
  text-align: center;
  img.img_number {
    width: 33px;
    height: 27px;
    margin-bottom: 10px;
  }
  .title {
    font-weight: 700;
    font-size: 26px;
    line-height: 31px;
    color: #000000;
    margin-bottom: 10px;
  }
  .desc {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #666666;
    .purple {
      color: #5d04d9;
    }
  }
  .desc_small {
    font-size: 10px;
    line-height: 13px;
    margin-bottom: 40px;
  }
  img.img_gift_card {
    width: 209px;
    margin: 30px auto;
  }
  .card_container {
    background: #ffffff;
    border-radius: 20px;
    padding: 40px 25px;
    text-align: center;
    width: 70%;
    max-width: 600px;
    margin: 0 auto 20px auto;
    @media (max-width: 991px) {
      padding: 40px 25px 10px 25px;
    }
    .card {
      width: 25%;
      display: inline-flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      @media (max-width: 991px) {
        width: 50%;
        margin-bottom: 30px;
      }
      .step {
        padding: 3px 10px;
        width: 60px;
        background: #5e5a66;
        border-radius: 4px;
        font-weight: 700;
        font-size: 12px;
        color: #ffffff;
        margin-bottom: 16px;
      }
      img.icon {
        width: 30px;
        height: 30px;
        margin-bottom: 10px;
      }
      .text {
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        color: #333;
        @media (max-width: 991px) {
          font-size: 14px;
        }
      }
    }
  }
  .button_detail {
    width: 310px;
    padding: 20px;
    background: #5e5a66;
    font-weight: 700;
    font-size: 18px;
    line-height: 21px;
    color: #ffffff;
  }
}
</style>
<style lang="scss" scoped>
img {
  max-width: none;
}
.promotion {
  overflow: hidden;
}
.inner {
  width: 360px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.apply_second_btn {
  padding: 18px 94px;
  background: #ffffff;
  border-radius: 10px;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  color: #5d04d9;
  margin-top: 40px;
  margin-bottom: 50px;
  font-weight: 700;
}
.apply_third_btn {
  padding: 18px 94px;
  background: #5d04d9;
  border-radius: 10px;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  color: #ffffff;
  margin-top: 40px;
  margin-bottom: 50px;
  font-weight: 700;
}
.toGuaranteeSectionBtn {
  width: 220px;
  height: 64px;
  background-image: url('//res.tutoring.co.kr/res/images/evt/202203/pt90/pt90_mid_cta2.png');
  background-size: cover;
  cursor: pointer;
  border-radius: 40px;
}
.modal--purchase {
  width: 350px;
  .common-modal__description--small {
    margin-top: 18px;
  }
}
/* 구매하기 cta */
.challenge-cta {
  position: fixed;
  bottom: -100%;
  right: 0;
  left: 0;
  z-index: 50;
  transition: bottom 0.4s;
  @media (max-width: 1199px) {
    & {
      bottom: 0;
    }
  }

  &.ani-bottom-up {
    bottom: 0;
  }

  & .challenge-cta__wrap {
    background: #5d04d9;
    height: 70px;
    width: 100%;
    font-weight: 700;
    font-size: 20px;
    color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    .icon_cta {
      display: inline-block;
      width: 24px;
      height: 24px;
      background: url(//res.tutoring.co.kr/res/images/img/marketprice/icon_cta.png) no-repeat 50%;
      background-size: contain;
      vertical-align: middle;
      margin-left: 10px;
    }
    @media (max-width: 1199px) {
      & {
        height: 70px;
        padding: 0 20px;
      }
    }
    &.sheetOn {
      background: #fff;
    }
    & .wrapper {
      width: 100%;
      max-width: 672px;

      & button {
        display: flex;
        width: 100%;
        height: 50px;
        background: #ff6668;
        border-radius: 10px;
        color: #fff;
        font-size: 20px;
        font-weight: 700;
        display: flex;
        align-items: center;
        justify-content: center;
        letter-spacing: -1px;
        @media (max-width: 1199px) {
          & {
            font-size: 15px;
            letter-spacing: -0.5px;
          }
        }
      }
    }
  }
}
.sheet {
  position: absolute;
  bottom: -800px;
  width: 100%;
  color: #000;
  transition: all 0.4s;
  z-index: -1;

  & .dim {
    display: none;
  }

  &.is-show {
    bottom: 84px;
    transition: all 0.4s;
    & .dim {
      display: block;
      position: fixed;
      top: 0;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.4);
      background-blend-mode: multiply;
      z-index: -1;
    }
  }

  & .sheet__wrap {
    border-radius: 10px 10px 0 0;
    background: #fff;
    padding: 0 20px;

    & .option {
      height: 40vh;
      overflow-y: scroll;
    }

    & .btn {
      position: relative;
      height: 50px;
      cursor: pointer;

      &:after {
        position: absolute;
        top: 30%;
        right: 0;
        left: 0;
        width: 10px;
        height: 10px;
        margin: auto;
        border-top: 2px solid #333;
        border-right: 2px solid #333;
        vertical-align: middle;
        box-sizing: border-box;
        transform: rotate(135deg) translateY(-50%);
        content: '';
      }
    }
  }
}
// 하단 버튼 작업
.challenge-cta.is-info {
  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 50;
  color: #000;
  transition: bottom 0.4s;
}

.promotion .challenge-cta .challenge-cta__wrap .wrapper .textwrap .heading {
  display: inline-flex;
  font-weight: 700;
  font-size: 12px;
  line-height: 12px;
  color: #999999;
  margin-right: 5px;
}

.promotion .challenge-cta .challenge-cta__wrap .wrapper .textwrap .time {
  font-weight: 700;
  font-size: 17px;
  line-height: 17px;
  color: #000;
}

/* 데스크톱 */
.promotion .challenge-cta.is-info .cta__button {
  height: 44px;
  display: flex;
  background: #3e83fe;
  border-radius: 5px;
  color: #fff;
  align-items: center;
  justify-content: center;
  // max-width: 500px;
  width: 100%;
  margin: auto;
  font-size: 15px;
  font-weight: 700;
  line-height: 15px;
}
.promotion .challenge-cta.is-info .cta__button .btnText {
  display: inline-block;
  vertical-align: middle;
  margin-right: 9px;
  margin-top: 1px;
}
.promotion .challenge-cta.is-info .cta__button .rightArrow {
  display: inline-block;
  width: 24px;
  height: 24px;
  background: url(//res.tutoring.co.kr/res/images/img/marketprice/icon_cta.png) no-repeat 50%;
  background-size: contain;
  vertical-align: middle;
}

@media (max-width: 1199px) {
  .promotion .challenge-cta.is-info .wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .promotion .challenge-cta.is-info .wrapper .textwrap {
    width: 25%;
    margin-right: 20px;
  }
  .promotion .challenge-cta.is-info .wrapper .btnwrap {
    width: 100%;
  }
  .promotion .challenge-cta.is-info .wrapper .btnwrap .cta__mobile {
    max-width: 100%;
  }
}

@media (min-width: 1199px) {
  .promotion .challenge-cta.is-info .wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .promotion .challenge-cta.is-info .wrapper .textwrap {
    width: 25%;
    margin-right: 20px;
  }
  .promotion .challenge-cta.is-info .wrapper .btnwrap {
    width: 100%;
  }
  .promotion .challenge-cta.is-info .wrapper .btnwrap .cta__mobile {
    max-width: 100%;
  }
}

@media (min-width: 320px) and (max-width: 767px) {
  .promotion .challenge-cta.is-info .wrapper .textwrap {
    margin-right: 10px;
    width: 60%;
  }
  .promotion .challenge-cta.is-info .cta__button {
    margin-top: 0;
    height: 44px;
    font-size: 15px;
    max-width: 100%;
    background: #3e83fe;
  }
}
</style>
